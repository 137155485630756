'use strict';

const $ = (window.jQuery = window.$ = require('jquery'));

let Selector = {
  countrySelect: '.js-country-select',
  regionSelect: '.js-region-select',
  provinceSelect: '.js-province-select',
  filterButton: '#searchLocation',
  mapTab: '#map-tab',
  destinationClass: '.js-destination-product',
  accomodationClass: '.js-accomodation-product',
  pinsClass: '.js-accomodation-pin',
  destinationCount: '#destinationCount',
  accomodationCount: '#accomodationCount',
  pinsCount: '#pinsCount',
  closeModalButton: '.filters-area .menu-close'
};

let bindEvent = () => {
  
  $(Selector.countrySelect).on('change', (e) => {
    let triggerValue = $(e.currentTarget).val();

    //reset the provice select
    $(Selector.regionSelect).val('0');

    

    //update the region select
    document.querySelectorAll(Selector.regionSelect + '>option').forEach((option) => {
      if ($(option).data().country == triggerValue || $(option).data().country == 'default' || triggerValue == 0) {
        $(option).show();
      } else {
        $(option).hide();
      }
    });

    //reset the provice select
    $(Selector.provinceSelect).val('0');

    

    //update the provice select
    document.querySelectorAll(Selector.provinceSelect + '>option').forEach((option) => {
      if ($(option).data().country == triggerValue || $(option).data().country == 'default' || triggerValue == 0) {
        $(option).show();
      } else {
        $(option).hide();
      }
    });

     
    
    
    
  });

  $(Selector.regionSelect).on('change', (e) => {
    let triggerValue = $(e.currentTarget).val();

    //reset the provice select
    $(Selector.provinceSelect).val('0');

    //update the provice select
    document.querySelectorAll(Selector.provinceSelect + '>option').forEach((option) => {
      if ($(option).data().region == triggerValue || $(option).data().region == 'default' || triggerValue == 0) {
        $(option).show();
      } else {
        $(option).hide(); 
      } 
    });
  });
 
  $(Selector.filterButton).on('click', (e) => {
    e.preventDefault();  

    //if we are on mobile viewport close filters modal
    if ($(window).width() < 992) {
      $(Selector.closeModalButton).click();
    }

    filterLocations();
   
  });

  $(Selector.mapTab).on('click', (e) => {
    e.preventDefault();  
    console.log ("MAP TAB!!");
    //if we are on mobile viewport close filters modal
    if ($(window).width() < 992) {
      $(Selector.closeModalButton).click();
    }

    filterLocations();
  });

  let filterLocations = () => {
    
    let province = $(Selector.provinceSelect).val();
    let region = $(Selector.regionSelect).val();
    let country = $(Selector.countrySelect).val();
    let accomodationsArr = document.body.querySelectorAll(Selector.accomodationClass);
    let destinationsArr = document.body.querySelectorAll(Selector.destinationClass);
    let pinsArr = document.body.querySelectorAll(Selector.pinsClass);

    if (province != '0') {
      let filterArr = province.split('-');
      let countryVal = filterArr[0];
      let regionVal = filterArr[1];
      let provinceVal = filterArr[2];

      resetResults(accomodationsArr);

      accomodationsArr.forEach((accomodation) => {
        let condition = accomodation.dataset.nation == countryVal && accomodation.dataset.region == regionVal && accomodation.dataset.province == provinceVal;
        toggleElement(condition, accomodation);
      });

      resetResults(destinationsArr);

      destinationsArr.forEach((destination) => {
        let condition = destination.dataset.nation == countryVal && destination.dataset.region == regionVal && destination.dataset.province == provinceVal;
        toggleElement(condition, destination);
      });

      resetResults(pinsArr);

      pinsArr.forEach((pin) => {
        let condition = pin.dataset.nation == countryVal && pin.dataset.region == regionVal && pin.dataset.province == provinceVal;
        toggleElement(condition, pin);

       
      });

    } else if (region != '0') {
      let filterArr = region.split('-');
      let countryVal = filterArr[0];
      let regionVal = filterArr[1];

      resetResults(accomodationsArr);

      accomodationsArr.forEach((accomodation) => {
        let condition = accomodation.dataset.nation == countryVal && accomodation.dataset.region == regionVal;
        toggleElement(condition, accomodation);
      });

      resetResults(destinationsArr);

      destinationsArr.forEach((destination) => {
        let condition = destination.dataset.nation == countryVal && destination.dataset.region == regionVal;
        toggleElement(condition, destination);
      });

      resetResults(pinsArr);

      pinsArr.forEach((pin) => {
        let condition = pin.dataset.nation == countryVal && pin.dataset.region == regionVal;
        toggleElement(condition, pin);
      });

      loadPins(pinsArr);
      
    } else if (country != '0') {
      resetResults(accomodationsArr);

      accomodationsArr.forEach((accomodation) => {
        let condition = accomodation.dataset.nation == country;
        toggleElement(condition, accomodation);
      });

      resetResults(destinationsArr);

      destinationsArr.forEach((destination) => {
        let condition = destination.dataset.nation == country;
        toggleElement(condition, destination);
      });

      resetResults(pinsArr);

      pinsArr.forEach((pin) => {
        let condition = pin.dataset.nation == country;
        toggleElement(condition, pin);
      });

    } else {
      let accomodationsArr = document.body.querySelectorAll(Selector.accomodationClass);
      let destinationsArr = document.body.querySelectorAll(Selector.destinationClass);
      
      resetResults(accomodationsArr);
      resetResults(destinationsArr);
      resetResults(pinsArr);
    }


    var text;
    //change results count
    let accomodationsCount = countElements(accomodationsArr);
    if(accomodationsCount > 1) {
      text = $(Selector.accomodationCount).hasClass('accom') ? $('#acc-plurale').val() : $('#acc-exp-plurale').val();
    } else if (accomodationsCount === 1) {
      text = $(Selector.accomodationCount).hasClass('accom') ? $('#acc-singolare').val() : $('#acc-exp-singolare').val();
    }
    $(Selector.accomodationCount).text(accomodationsCount + ' ' + text);

    let destinationsCount = countElements(destinationsArr);
    if(destinationsCount > 1) {
      text = $(Selector.destinationCount).hasClass('destin') ? $('#dest-plurale').val() : $('#dest-exp-plurale').val();
    } else if (destinationsCount === 1) {
      text = $(Selector.destinationCount).hasClass('destin') ? $('#dest-singolare').val() : $('#dest-exp-singolare').val();
    }
    $(Selector.destinationCount).text(destinationsCount + ' ' + text);
    

    let pinsCount = countElements(pinsArr);
    $(Selector.pinsCount).text(pinsCount);

    console.log("LOAD PINS");
    loadPins(pinsArr);

  };
};


let resetResults = (resultsArr) => {
  resultsArr.forEach((result) => {
    $(result).show();
    $(result).attr('data-active', true);
  });
};

let toggleElement = (condition, element) => {
  if (condition) {
    $(element).show();
    $(element).attr('data-active', true);
  } else {
    $(element).hide();
    $(element).attr('data-active', false);
  }
};

let loadPins = function (pinsArr) {
  let vueApp = window.StorelocatorApp.$children[1];
  if (vueApp && typeof vueApp.removeAllMarkers === 'function') {
      vueApp.removeAllMarkers();
      pinsArr.forEach(function (pin) {
          if (pin && pin.id) {
              if ($('#' + pin.id).is(':visible') || pin.dataset.active == 'true') {
                  vueApp.addMarker(pin.id);
              }
          }
      });
      if (typeof vueApp.fitBoundsPerPins === 'function') {
          vueApp.fitBoundsPerPins();
      }
  }
}; 

let countElements = (elements) => {
  let count = 0;
  elements.forEach((element) => {
    if (element.dataset.active == 'true') count++;
  });
  return count;
};

module.exports = function() {
  bindEvent();
};
