'use strict';
const $ = (window.jQuery = window.$ = require('jquery'));

var cart = require('BASE/cart/cart');

var updateMiniCart = true;

let bindEvent = () => {
  cart();

  $('.minicart').on('count:update', function (event, count) {
    if (count && $.isNumeric(count.quantityTotal)) {
      $('.minicart .minicart-quantity').text(count.quantityTotal);
      $('.minicart .minicart-link').attr({
        'aria-label': count.minicartCountOfItems,
        title: count.minicartCountOfItems
      });
    }
  });

  $('.minicart').on('mouseenter focusin touchstart', function() {
    if ($('.search:visible').length === 0) {
      return;
    }
    var url = $('.minicart').data('action-url');
    var count = parseInt($('.minicart .minicart-quantity').text(), 10);

    if (count === 0 && $('.minicart .empty-minicart-popover.show').length === 0) {
      $('.minicart .empty-minicart-popover').addClass('show');
    }

    if (count !== 0 && $('.minicart .popover.show').length === 0) {
      if (!updateMiniCart) {
        $('.minicart .popover').addClass('show');
        return;
      }

      $('.minicart .popover').addClass('show');
      $('.minicart .popover')
        .spinner()
        .start();
      $.get(url, function(data) {
        $('.minicart .popover').empty();
        $('.minicart .popover').append(data);
        updateMiniCart = false;
        $.spinner().stop();
      });
    }
  });

  $('.minicart .minicart-link').on('click', function(e) {
    var count = parseInt($('.minicart .minicart-quantity').text(), 10);

    if (count === 0) {
      e.preventDefault();
    } else {
      var moBreak = getComputedStyle(document.documentElement).getPropertyValue(`--breakpoint-sm`);
      if (window.matchMedia(`(max-width: ` + moBreak + `)`).matches) {
        $.spinner().start();
        $(e.target).attr('disabled', true);
      }
    }
  });

  $('body').on('touchstart click', function(e) {
    if ($('.minicart').has(e.target).length <= 0) {
      $('.minicart .empty-minicart-popover').removeClass('show');
      $('.minicart .popover').removeClass('show');
    }
  });

  $('.minicart').on('mouseleave focusout', function(event) {
    if (
      (event.type === 'focusout' && $('.minicart').has(event.target).length > 0) ||
      (event.type === 'mouseleave' && $('.minicart .quantity-form').find(event.target).length) ||
      $('body').hasClass('modal-open')
    ) {
      event.stopPropagation();
      return;
    }
    $('.minicart .empty-minicart-popover').removeClass('show');
    $('.minicart .popover').removeClass('show');
  });

  $('body').on('click', '.js-quantity-add', function () {
    var uuid = $(this).data('uuid'); 
    var quantityInput = $(`#quantity-minicart-${uuid}`); 
    var quantityVal = parseInt(quantityInput.val());
    var quantityMax = parseInt(quantityInput.data('max'));

    if (quantityVal + 1 <= quantityMax) {
        quantityInput.val(quantityVal + 1);
        $(`#quantity-text-${uuid}`).text(quantityVal + 1); 
        quantityInput.trigger('change'); 
    }
  }); 
  $('body').on('click', '.js-quantity-remove', function () {
    var uuid = $(this).data('uuid'); 
    var quantityInput = $(`#quantity-minicart-${uuid}`); 
    var quantityVal = parseInt(quantityInput.val());
    var quantityMin = parseInt(quantityInput.data('min'));

    if (quantityVal - 1 >= quantityMin) {
        quantityInput.val(quantityVal - 1);
        $(`#quantity-text-${uuid}`).text(quantityVal - 1); 
        quantityInput.trigger('change'); 
    }
  });

  $('body').on('change', '.minicart .quantity', function() {
    if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
      location.reload();
    }
  });

  $('body').on('product:afterAddToCart', function() {
    updateMiniCart = true;
  });
  $('body').on('cart:update', function() {
    updateMiniCart = true;
  });
};

module.exports = function() {
  bindEvent();
  console.log('Minicart component imported');
};