const $ = (window.jQuery = window.$ = require('jquery'));
const accessibleAutocomplete = (window.accessibleAutocomplete = require('accessible-autocomplete/dist/accessible-autocomplete.min.js'));
const processInclude = require('BASE/util');

$.fn.size = function() {
  return this.length;
};

$(document).ready(() => {
  processInclude(require('./components/menu'));
  require('what-input');
  processInclude(require('BASE/components/cookie'));
  processInclude(require('./components/consentTracking'));
  processInclude(require('BASE/components/footer'));
  require('./cart');
  //processInclude(require('BASE/components/miniCart'));
  processInclude(require('BASE/components/collapsibleItem'));
  processInclude(require('./components/search'));
  processInclude(require('./components/clientSideValidation'));
  processInclude(require('BASE/components/countrySelector'));
  processInclude(require('BASE/components/toolTip'));
  //processInclude(require('./components/contactUs'));
  processInclude(require('./components/header'));
  processInclude(require('./components/product-bundle'));
  //processInclude(require('BASE/product/base'));
  processInclude(require('./components/accordion'));
  processInclude(require('./components/overlay'));
  // processInclude(require('./search/search'));
  processInclude(require('./components/notifications'));
  processInclude(require('./components/updateVariant'));
  processInclude(require('./login/passwordToggle'));
  processInclude(require('./components/checkout-flow'));
  processInclude(require('./product/registration'));
  processInclude(require('./product/accomodations'));
  processInclude(require('./components/custom-quantity'));
  processInclude(require('./components/accessories-carousel'));
  processInclude(require('./components/fast-paste'));
  processInclude(require('./components/text-summary'));
  processInclude(require('./components/error-reload'));
  processInclude(require('./components/carousel'));
  processInclude(require('./components/miniCart'));
  processInclude(require('./components/quick-buy'));
  processInclude(require('./components/editorial-map'));
  processInclude(require('./components/duet-date'));
  processInclude(require('./components/bookingDetail'));
  processInclude(require('./addressBook/addressBook'));
});

require('./global');
require('./thirdParty/boostrap');
require('./components/spinner');
require('slick-carousel/slick/slick');
require('./common/common');
//require('./thirdParty/accessibleautocomplete');

